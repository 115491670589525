import React from "react";

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
      }}
    >
      <div
        className="bg-theme-purple container text-gray-100 py-5 px-6 sm:px-12 sm:py-6 xl:px-16 xl:py-8 shadow-xl rounded-lg"
        style={{
          minWidth: "300px",
          maxWidth: "600px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            marginBottom: "20px",
          }}
        >
          <h2
            style={{
              fontSize: "20px", // Adjusts font size for the header
              fontWeight: "bold", // Makes header text bold
              marginBottom: "15px", // Adds space below the header
            }}
          >
            Subscribe for latest news and special offerings!
          </h2>
          {children}
        </div>
        <hr
          style={{
            borderColor: "rgba(255, 255, 255, 0.2)",
          }}
        />
        <button
          type="button"
          onClick={onClose}
          style={{
            marginTop: "20px",
            background: "transparent",
            border: "1px solid #fff",
            borderRadius: "4px",
            padding: "10px 20px",
            cursor: "pointer",
            alignSelf: "flex-end",
            color: "inherit",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default Modal;
