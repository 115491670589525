// /* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
import './SoftwareCostCalculator.css';
import React, { Component } from "react";
import Header from "parts/Header";
import Footer from "parts/Footer";
import Data from "json/landingPage.json";
import PricingLayout from "parts/PricingLayout";
import Button from 'elements/Button';
import FaqList from 'parts/FaqList';
// eslint-disable-block

export default class PricingMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      totalC: 0,
    };

    this.features = [
      { name: "Custom Mobile App", cost: 20000, description: "A fully bespoke mobile application tailored to your brand and business requirements, offering unique functionality and a standout user experience." },
      { name: "Hybrid App (iOS & Android)", cost: 15000, description: "A versatile application compatible with both iOS and Android platforms, designed for businesses seeking a broad user reach without the cost of separate native apps." },
      { name: "Native app (iOS or Android)", cost: 8000, description: "A high-performance application built specifically for either iOS or Android, providing an optimized experience by leveraging platform-specific capabilities." },
      { name: "User Authentication", cost: 2000, description: "Secure sign-in functionality to ensure that access to your app is protected and personalized, enhancing user trust and safety." },
      { name: "Email Integration Contact Us", cost: 1200, description: "Implementation of a reliable email contact system within your app, allowing for smooth customer communication and support inquiries directly through the app." },
      { name: "Logo Design", cost: 145, description: "Professional logo creation to establish your brand's visual identity, designed by our creative team to resonate with your target audience." },
      { name: "Cloud Database Integration", cost: 10000, description: "Robust cloud-based storage solutions for your app, ensuring scalable, secure, and accessible data management across devices." },
      { name: "E-commerce", cost: 4300, description: "Integration of online shopping capabilities into your app, including product listings, cart functionality, and user account management for a seamless shopping experience." },
      { name: "Payment Processing", cost: 13000, description: "Implementation of a secure and efficient payment system, allowing for various payment methods and currencies, giving customers a smooth checkout process." },
    ];

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.calculatetotalC = this.calculatetotalC.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleCheckboxChange(event) {
    const featureName = event.target.name;
    const isChecked = event.target.checked;
    const { selected } = this.state;

    if (isChecked) {
      this.setState({ selected: [...selected, featureName] }, () => {
        this.calculatetotalC();
      });
    } else {
      this.setState({
        selected: selected.filter(
          (name) => name !== featureName,
        ),
      }, () => {
        this.calculatetotalC();
      });
    }
  }

  calculatetotalC() {
    const { selected } = this.state;
    let cost = 0;
    selected.forEach((feature) => {
      const selectedFeature = this.features.find((f) => f.name === feature);
      cost += selectedFeature.cost;
    });
    this.setState({ totalC: cost });
  }
  render() {
    return (
      <>
        <Header {...this.props} />
        <section className="flex container mx-auto justify-center">
        <div className="flex flex-col w-10/12 sm:w-11/12 xl:w-10/12 rounded-2xl bg-theme-purple text-gray-100 py-14 sm:px-12 sm:py-20 xl:px-16 shadow-2xl discuss">

          <h1 className="w-2/3 text-5xl sm:text-2xl leading-tight font-semibold">Mobile App Functionality Calculator</h1>
          <form>
            {this.features.map((feature) => (
              <div key={feature.name} className="flex container mx-auto justify-center mb-5 mt-2 checkbox">
                <input
                  type="checkbox"
                  id={feature.name}
                  name={feature.name}
                  value={feature.name}
                  onChange={this.handleCheckboxChange}
                />
                <label htmlFor={feature.name}>
                  {feature.name} (${feature.cost})
                  <span className="footnote">{feature.description}</span>

                </label>
              </div>

            ))}
          </form>
          <div>Total Cost: ${this.state.totalC}</div>
          <div>Selected Features: {this.state.selected.join(", ")}</div>
        </div>
        </section>
        <Button className="flex  justify-center mb-12 text-lg mx-auto ml-3 px-6 py-2 bg-theme-purple text-white rounded-full border-2 border-theme-purple hover:bg-dark-theme-purple border-purple-800 transition duration-200" type="link" href="/discuss-project">
              Contact Us
        </Button>
        <FaqList />
        <PricingLayout data={Data.pricing} />
        <Footer />
      </>
    );
  }
}
