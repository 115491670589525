import React, { useState, useEffect } from 'react';
import Modal from '../parts/Model';
import { db, collection, addDoc } from '../firebase-config';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Check if the modal has already been shown in this session
    const isModalShown = sessionStorage.getItem('isModalShown');

    if (!isModalShown) {
      // If not, set a timeout to show the modal after 2 seconds
      const timer = setTimeout(() => {
        setShowModal(true);
        // Record that the modal has been shown in this session
        sessionStorage.setItem('isModalShown', 'true');
      }, 2000);

      // Clear the timeout if the component unmounts
      return () => clearTimeout(timer);
    }
    // Empty dependency array means this effect runs once on mount
    return () => {};
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await addDoc(collection(db, 'subscribers'), {
        email,
        subscribedAt: new Date(),
      });
      alert('Thank you for subscribing!');
      setEmail('');
      setShowModal(false); // Close the modal
    } catch (error) {
      console.error("Error adding subscriber: ", error);
    }
  };

  return (
    <Modal show={showModal} onClose={() => setShowModal(false)}>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          id="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit">Subscribe</button>
      </form>
    </Modal>
  );
};

export default NewsletterForm;

// const NewsletterForm = () => {
//     const [email, setEmail] = useState('');

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         try {
//           await addDoc(collection(db, 'subscribers'), {
//             email,
//             subscribedAt: new Date(),
//           });
//           alert('Thank you for subscribing!');
//           setEmail('');
//         } catch (error) {
//           console.error("Error adding subscriber: ", error);
//         }
//       };

//     return (
//         <form className="flex flex-col w-10/12 sm:w-11/12 xl:w-10/12 rounded-2xl bg-theme-purple text-gray-100 py-8 sm:px-5 sm:py-8 xl:px-5 shadow-2xl discuss" onSubmit={handleSubmit}>
//           <input
//             type="email"
//             id="email"
//             value={email}
//             onChange={(e) => setEmail(e.target.value)}
//             required
//           />
//           <button type="submit">Subscribe</button>
//         </form>
//       );
//     };

//   export default NewsletterForm;
