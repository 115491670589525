import React, { useState, useEffect } from "react";
import "./styles.css";
import image from "assets/images/Startup.png";

const GrassAnimation = () => {
  const [cutting, setCutting] = useState(false);
  const [mowerPosition, setMowerPosition] = useState(0); // Position of the lawn mower

  useEffect(() => {
    setCutting(true);
    const mowerMovement = setInterval(() => {
      setMowerPosition((prevPosition) => {
        const newPosition = prevPosition + 5; // Adjust this for mower speed
        if (newPosition > window.innerWidth) {
          // If mower goes out of screen, reset
          clearInterval(mowerMovement);
          setCutting(false);
          return 0;
        }
        return newPosition;
      });
    }, 100); // Adjust this for mower animation interval

    return () => clearInterval(mowerMovement); // Cleanup on component unmount
  }, []);

  return (
    <div className="grassContainer">
      {Array(100)
        .fill(0)
        .map((idx) => (
          <div
            key={idx}
            className="grassBlade"
            style={{
              height: "50px",
              width: "100%",
              animation: cutting ? "cutGrass 2s infinite" : "none",
            }}
          />
        ))}
      <div className="lawnMower" style={{ left: mowerPosition }} />
      <img
        style={{ left: mowerPosition }}
        src={image}
        alt="Lawn Mower"
        className="lawnMower"
      />
    </div>
  );
};

export default GrassAnimation;
