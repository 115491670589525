// FaqItem.js
import React, { useState } from 'react';
import Button from 'elements/Button';

const FaqItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="faq-item  bg-theme-purple text-gray-100 py-5 sm:px-5 sm:py-5 xl:px-5 shadow-1xl discuss">
      <Button className="faq-question" type="button" onClick={toggle}>
        {question}
      </Button>
      {isOpen && <div className="ml-12">{answer}</div>}
    </div>
  );
};

export default FaqItem;
